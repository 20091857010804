import React, {useState, Fragment} from "react";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { Modal } from "react-bootstrap";
import QueryFilter from "./QueryFilter/index";
import useToast from "../hooks/use-toast";
import {
  Button,
  FormSelect,
  Row,
  Collapse
} from "shards-react";

import fields from "./QueryFilter/fields";
import { findWhere, union } from "underscore";

export const GET_REPORT = gql`
  query getReport($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject, $columns: Array, $name: String) {
    getReport(selector: $selector, limit: $limit, skip: $skip, sort: $sort, columns: $columns, name: $name) {
      url
    }
  }
`;



const ExportButton = ({ queryReport = {}, columns, projector = {}, name, collection}) => {

  const [columnsToUse, setColumnsToUse ] = useState(columns);
  const [query, setQuery] = useState({
    skip : true
  });

  const [selector, setSelector] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //state of the collapse of advanced search
  const [ active, toggleActive ] = useState(false);

  const { loadingToast, completeToast, errorToast} = useToast();

  const {data, loading, error, refetch} = useQuery(GET_REPORT, query);

  // if ( !!error ) {
  //   console.log(error);
  //   toast.error("Error:", error.message);
  // }

  //Again, hack to fix Sylvain's strange decision to call the collection actions in some places and transactions elsewhere
  const collectionFields = collection == 'supporters' && fields[collection] || fields['transactions'];

  //reorder the different fields so they appear correctly and in a constant way in the export
  const orderedSelector = () => {
    let ordered = [];

    Object.keys(collectionFields).forEach( category => {
      collectionFields[category].forEach(field => {
        let present = findWhere(selector, {key : field.key})
        if(!!present) {
          ordered.push(field);
        }
      });
    })

    return ordered;

  }

  //if we're looking for collection supporters and no isDeceased key is passed,
  //make sure to add a isDeceased default to false.
  const defaultNotDeceased = () => {
    const newSelector = queryReport;
    if(collection === "supporters" && queryReport.isDeceased === undefined) {
      newSelector.isDeceased = false;
    }
    return newSelector;
  }

  
  //if we're looking for collection supporters and no isDeceased key is passed,
  //make sure to add a isDeceased default to false.
  const defaultNotDeceasedArchivedVolunteer = () => {
    const newSelector = queryReport;
    if(collection === "supporters" && queryReport.isDeceased === undefined) {
      newSelector.isDeceased = false;
    }
    if(collection === "supporters" && queryReport.isArchived === undefined) {
      newSelector.isArchived = false;
    }
   
    return newSelector;
  }


  
  //if we're looking for collection supporters and no isArchived key is passed,
  //make sure to add a isArchived default to false.
  // consolidate with other selector
  const defaultNotArchived = () => {
    const newSelector = queryReport;
    if(collection === "supporters" && queryReport.isArchived === undefined) {
      newSelector.isArchived = false;
    }
    return newSelector;
  }


    function handleSubmit () {
        const conf = window.confirm("This report may contain confidential personal. You should not download this report if you are using public WIFI or a computer you do not trust. Continue anyway?");

        if ( !conf )  {
            return toast.warning(`Report canceled`);
        }

        loadingToast(`Generating your export...`);

        //if selector was set through a preset or manaul selection, use that
        const ordered = orderedSelector();
        setQuery({
            variables : {
                // idArray: supportersInfo,
                selector : defaultNotDeceasedArchivedVolunteer(),
                projector,
                columns : !!ordered.length && ordered || columnsToUse,
                name
            },
            fetchPolicy :"network-only",
            onCompleted : ({ getReport }) => {
                completeToast("Export Generated");
                if ( !getReport ) {
                    return console.log('no report', getReport);
                }
                window.open(getReport.url);
                setQuery({
                    skip : true
                })
            },
            onError: (e) => {
                console.log('error', Object.keys(e), e.graphQLErrors );
                if ( !!e.graphQLErrors && !!e.graphQLErrors.length )  {
                    errorToast(e.graphQLErrors[0].message);
                } else {
                    errorToast("Error with export");
                }
            }
        });
    }

  return (
    <Fragment>
      <Button
        onClick={handleShow}>
        Export <i className='material-icons'>get_app</i>
      </Button>
      <Modal
    size="md"
    // centered
    show={show}
    onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <div>
            Choose an existing fields selection :
          </div>
          <div className="mb-4">
              <FormSelect
                  size="sm"
                  style={{ maxWidth: "130px" }}
                  onChange={(e) => {
                      const type = e.target.value;
                      const fieldValues = union( ...Object.values( collectionFields ) );

                      if ( type == 'analysis' ) {
                          const analysis = ["_id", "PIN", "date", "data.billingDate", "data.paymentType",
                              "data.paymentMethod", "data.amount", "data.status", "campaign.nickname", "province",
                              "createdDate", "lastUpdated", "isDeceased", "PACStatus", "lastPACAmount", "currentPACAmount",
                              "lastPACSuccess", "donationHistory.totalCount",
                              "donationHistory.totalAmount", "donationHistory.dateFirstDonation",
                              "donationHistory.dateLastDonation", "donationHistory.amountLastDonation",
                              "donationHistory.typeLastDonation"]
                              .map(fieldName => {
                                  return fieldValues.find(fv => fv.key == fieldName)
                              });
                          setSelector(analysis);
                      }

                      if ( type == 'mail' ) {
                          const mailFields = ["_id", "PIN", "prefix", "firstName", "lastName",
                              "address1", "address2", "city", "province", "postalCode",
                              "country", "homePhone", "mobilePhone", "emails.0.address",
                              "emails.0.optInEn", "emails.0.optInFr", "contactPreferences.okMail",
                              "contactPreferences.okContact", "contactPreferences.okContactRegular",
                              "language", "householdMembers", "isDeceased", "isArchived", "merged", "PACStatus", "lastPACAmount",
                              "lastPACSuccess", "donationHistory.dateLastDonation", "donationHistory.amountLastDonation",
                              "donationHistory.typeLastDonation", "donationHistory.minDonationOTG",
                              "donationHistory.maxDonationOTG", "donationHistory.meanDonationOTG",
                              "donationHistory.totalTwelveMonths"].map(fieldName => {
                              return fieldValues.find(fv => fv.key == fieldName)
                          });
                          setSelector(mailFields);
                      }

                      if ( type == 'call' ) {
                          const callFields = ["_id", "PIN", "prefix", "firstName", "lastName",
                              "address1", "address2", "city", "province", "postalCode",
                              "country", "homePhone", "mobilePhone", "emails.0.address",
                              "emails.0.optInEn", "emails.0.optInFr", "contactPreferences.okCall",
                              "contactPreferences.okContact",
                              "language", "isDeceased", "isArchived", "isUnknown", "merged", "PACStatus",
                              "donationHistory.dateLastDonation", "donationHistory.amountLastDonation",
                              "donationHistory.typeLastDonation", "donationHistory.minDonationOTG",
                              "donationHistory.maxDonationOTG", "donationHistory.meanDonationOTG",
                              "donationHistory.totalTwelveMonths"]
                              .map(fieldName => {
                                  return fieldValues.find(fv => fv.key == fieldName)
                              });
                          setSelector(callFields);
                      }

                      if ( type == 'reactivate' ) {
                          const reactivateFields = ["PIN", "firstName", "lastName",
                              "address1", "address2", "city", "province", "postalCode",
                              "homePhone", "mobilePhone", "emails.0.address",
                              "language", "createdDate", "lastUpdated", "PACStatus", "donationHistory.totalAmount",
                              "currentPACAmount", "lastPACAmount", "lastPACSuccess", "donationHistory.dateFirstDonation",
                              "donationHistory.dateLastDonation", "donationHistory.amountLastDonation",
                              "donationHistory.typeLastDonation"]
                              .map(fieldName => {
                                  return fieldValues.find(fv => fv.key == fieldName)
                              });
                          setSelector(reactivateFields);
                      }

                      if ( type == 'all' ) {
                          const allFields = ["_id", "PIN", "ENSupporterId", "source", "prefix", "firstName", "lastName",
                              "address1", "address2", "city", "province", "postalCode", "country", "ridingCode", "ridingName",
                              "MPFirstName", "MPLastName", "MPParty", "homePhone", "mobilePhone", "emails.0.address",
                              "emails.0.optInEn", "emails.0.optInFr", "emails.0.okNewsletter", "emails.0.okFund",
                              "contactPreferences.okEmail", "contactPreferences.okMail", "contactPreferences.okCall",
                              "contactPreferences.okEvents", "contactPreferences.okSMS", "contactPreferences.okTrade",
                              "contactPreferences.okSurveys", "contactPreferences.okContact",
                              "contactPreferences.okContactRegular", "contactPreferences.okPremiums",
                              "language", "PACStatus", "isVolunteer", "isUnknown", "createdDate", "lastUpdated", "householdMembers",
                              "donationHistory.totalAmount", "merged", "isDeceased", "isArchived",
                              , "lastPACAmount", "lastPACSuccess", "donationHistory.totalCount",
                              "donationHistory.dateFirstDonation", "donationHistory.totalAmount",
                              "donationHistory.countTwelveMonths", "donationHistory.totalTwelveMonths",
                              "donationHistory.countThisYear", "donationHistory.totalThisYear",
                              "donationHistory.dateLastDonation", "donationHistory.amountLastDonation",
                              "donationHistory.typeLastDonation", "donationHistory.minDonationOTG",
                              "donationHistory.maxDonationOTG", "donationHistory.meanDonationOTG", "data.opts.utm_source",
                              "data.opts.utm_campaign", "data.opts.utm_content", "data.opts.receive_gift_optin", "data.opts.socks-selection", "data.opts.mailTrackingCode",
                              "source", "user.firstName", "user.lastName" ]
                              .map(fieldName => {
                                  return fieldValues.find(fv => fv.key == fieldName)
                              });
                          setSelector(allFields);
                      }

                  }}>
                  <option value="default">Default</option>
                  <option value="analysis">Analysis</option>
                  <option value="mail">Mailing</option>
                  <option value="call">Telemarketing</option>
                  <option value="reactivate">Reactivation</option>
                  <option value="all">All Fields</option>

              </FormSelect>
          </div>

          <a href="#"
            onClick = {() => toggleActive(!active)} className="mb-2">
            Advanced field selection
          </a>
          <div className="mb-2">
           <Collapse open={active} timeout={0}>
            <QueryFilter
              selector={selector}
              setSelector={setSelector}
              collection={collection}
            />
           </Collapse>
          </div>
        <Row className="justify-content-between">
          <Button
            outline
            theme="danger"
            className="export-button mt-4 mx-3"
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
          className="export-button mt-4 mx-3"
          onClick={handleSubmit}
          >Export
        </Button>
      </Row>
      </Modal.Body>
      </Modal>
    </Fragment>
  )

}

export default ExportButton;
