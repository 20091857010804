
const fields = {
  supporters: {
    IDs : [
      { key: '_id', header: 'Supporter ID' },
      { key: 'PIN', header: 'PIN' },
      { key: 'ENSupporterId', header: 'ENSupporter ID'},
      { key: 'customerId', header: 'Customer ID'},
      { key: 'source', header : 'Source'}
    ],
    Name : [
      { key: 'prefix', header: 'Prefix'},
      { key: 'firstName', header: 'First Name' },
      { key: 'lastName', header: 'Last Name' }
    ],
    Address : [
      { key: 'address1', header: 'Address 1' },
      { key: 'address2', header: 'Address 2' },
      { key: 'city', header: 'City' },
      { key: 'province', header: 'Province' },
      { key: 'postalCode', header: 'Postal Code' },
      { key: 'country', header : 'Country'},
      { key: 'ridingCode', header: 'Riding Code' },
      { key: 'ridingName', header: 'Riding Name' },
      { key: 'MPFirstName', header: 'MP First Name' },
      { key: 'MPLastName', header: 'MP Last Name' },
      { key: 'MPParty', header : 'MP Party'}
    ],
    'Contact Information' : [
      { key: 'homePhone', header: 'Home Phone' },
      { key: 'mobilePhone', header: 'Mobile Phone' },
      { key: 'emails.0.address', header: 'email'},
      { key: 'emails.0.optInEn', header: 'Opt-in EN' },
      { key: 'emails.0.optInFr', header: 'Opt-in FR' },
      { key: 'emails.0.okNewsletter', header: 'Ok Newsletter'},
      { key: 'emails.0.okFund', header: 'Ok Fund' },
    ],
    'Contact Preferences' : [
      { key: 'contactPreferences.okEmail', header: 'Ok email' },
      { key: 'contactPreferences.okMail', header: 'Ok Mail' },
      { key: 'contactPreferences.okCall', header: 'Ok Call'},
      { key: 'contactPreferences.okEvents', header: 'Ok Events' },
      { key: 'contactPreferences.okSMS', header: 'Ok SMS' },
      { key: 'contactPreferences.okTrade', header: 'Ok Trade'},
      { key: 'contactPreferences.okSurveys', header: 'Ok Surveys' },
      { key: 'contactPreferences.okContact', header: 'Ok Contact' },
      { key: 'contactPreferences.okContactRegular', header: 'OK Contact Regular'},
      { key: 'contactPreferences.okPremiums', header: 'Ok Premiums'},
      { key: 'contactPreferences.okPACAsk', header: 'Ok PAC Ask'}
    ],
    'Supporter Info' : [
      { key: 'language', header: 'Language' },
      { key: 'isVolunteer', header: 'Volunteer'},
      { key: 'isUnknown', header: 'Unknown'},
      { key: 'createdDate', header: 'Created Date' },
      { key: 'lastUpdated', header: 'Last Updated' },
      { key: 'householdMembers', header: 'Household Members'},
      { key: 'merged', header: 'Merged'},
      { key: 'isDeceased', header: 'Deceased' },
      { key: 'isArchived', header: 'Archived' }
    ],
    'Donations' : [
      { key: 'PACStatus', header: 'PAC Status' },
      { key: 'lastPACAmount', header: 'Last PAC Amount' },
      { key: 'currentPACAmount', header: 'Current PAC Amount' },
      { key: 'lastPACSuccess', header: 'Last PAC Date' },
      { key: 'donationHistory.totalCount', header: 'Total Count' },
      { key: 'donationHistory.totalAmount', header: 'Total Amount' },
      { key: 'donationHistory.countTwelveMonths', header: 'Count Last 12 months' },
      { key: 'donationHistory.totalTwelveMonths', header: 'Total Last 12 Months' },
      { key: 'donationHistory.countThisYear', header: 'Count This Year' },
      { key: 'donationHistory.totalThisYear', header: 'Total This Year' },
      { key: 'donationHistory.dateFirstDonation', header: 'Date First Donation' },
      { key: 'donationHistory.dateLastDonation', header: 'Date Last Donation' },
      { key: 'donationHistory.amountLastDonation', header: "Amount Last Donation"  },
      { key: 'donationHistory.typeLastDonation', header: "Type Last Donation"  },
      { key: 'donationHistory.minDonationOTG', header: 'Min OTG' },
      { key: 'donationHistory.maxDonationOTG', header: 'Max OTG' },
      { key: 'donationHistory.meanDonationOTG', header: 'Mean OTG' }
    ]
  },
  transactions: {
    IDs : [
      { key: '_id', header: 'Supporter ID' },
      { key: 'PIN', header: 'PIN' },
      { key: 'ENSupporterId', header: 'ENSupporter ID'},
      { key: 'customerId', header: 'Customer ID'},
      { key: 'source', header : 'Source'}
    ],
    Transaction : [
      { key: 'date', header: 'Date' },
      { key: 'data.billingDate', header: 'Billing Date' },
      { key: 'data.paymentType', header: 'Payment Type' },
      { key: 'data.paymentMethod', header: 'Payment Method' },
      { key: 'data.amount', header: 'Amount' },
      { key: 'data.status', header: 'Status' },
      { key: 'campaign.nickname', header: 'Campaign' },
    ],
    Names : [
      { key: 'firstName', header: 'First Name' },
      { key: 'lastName', header: 'Last Name' },
      { key: 'prefix', header: 'Prefix'}
    ],
    Addresses : [
      { key: 'address1', header: 'Adddress 1' },
      { key: 'address2', header: 'Adddress 2' },
      { key: 'city', header: 'City' },
      { key: 'province', header: 'Province' },
      { key: 'postalCode', header: 'Postal Code' },
      { key: 'country', header : 'Country'},
      { key: 'ridingCode', header: 'Riding Code' },
      { key: 'ridingName', header: 'Riding Name' },
      { key: 'MPFirstName', header: 'MP First Name' },
      { key: 'MPLastName', header: 'MP Last Name' },
      { key: 'MPParty', header : 'MP Party'}
    ],
    'Contact Information' : [
      { key: 'homePhone', header: 'Home Phone' },
      { key: 'mobilePhone', header: 'Mobile Phone' },
      { key: 'emails.0.address', header: 'email'},
      { key: 'emails.0.optInEn', header: 'Opt-in EN' },
      { key: 'emails.0.optInFr', header: 'Opt-in FR' },
      { key: 'emails.0.okNewsletter', header: 'Ok Newsletter'},
      { key: 'emails.0.okFund', header: 'Ok Fund' },
    ],
    'Contact Preferences' : [
      { key: 'contactPreferences.okEmail', header: 'Ok email' },
      { key: 'contactPreferences.okMail', header: 'Ok Mail' },
      { key: 'contactPreferences.okCall', header: 'Ok Call'},
      { key: 'contactPreferences.okEvents', header: 'Ok Events' },
      { key: 'contactPreferences.okSMS', header: 'Ok SMS' },
      { key: 'contactPreferences.okTrade', header: 'Ok Trade'},
      { key: 'contactPreferences.okSurveys', header: 'Ok Surveys' },
      { key: 'contactPreferences.okContact', header: 'Ok Contact' },
      { key: 'contactPreferences.okContactRegular', header: 'OK Contact Regular'},
      { key: 'contactPreferences.okPremiums', header: 'Ok Premiums'},
      { key: 'contactPreferences.okPACAsk', header: 'Ok PAC Ask'}
    ],
    'Supporter Info' : [
      { key: 'language', header: 'Language' },
      { key: 'PACStatus', header: 'PAC Status' },
      { key: 'isVolunteer', header: 'Volunteer'},
      { key: 'createdDate', header: 'Created Date' },
      { key: 'lastUpdated', header: 'Last Updated' },
      { key: 'householdMembers', header: 'Household Members Ids' },
      { key: 'merged', header: 'merged'},
      { key: 'isDeceased', header: 'Deceased' },
      { key: 'isArchived', header: 'Archived' }
    ],
    'Tracking' : [
      { key: 'data.opts.utm_source', header: 'UTM Source' },
      { key: 'data.opts.utm_campaign', header: 'UTM Campaign' },
      { key: 'data.opts.utm_content', header: 'UTM Content'},
      { key: 'data.opts.receive_gift_optin', header: 'Receive Gift'},
      { key: 'data.opts.socks-selection', header: 'Socks Selection'},
      { key: 'data.opts.mailTrackingCode', header: "Mail Tracking Code"},
      { key: 'source', header: 'Source' },
      { key : "user.firstName", header : "User First Name"},
      { key : "user.lastName", header : "User Last Name"},
    ],
    'Donations' : [
      { key: 'donationHistory.totalCount', header: 'Total Count' },
      { key: 'donationHistory.totalAmount', header: 'Total Amount' },
      { key: 'donationHistory.countTwelveMonths', header: 'Count Last 12 months' },
      { key: 'donationHistory.totalTwelveMonths', header: 'Total Last 12 Months' },
      { key: 'donationHistory.countThisYear', header: 'Count This Year' },
      { key: 'donationHistory.totalThisYear', header: 'Total This Year' },
      { key: 'donationHistory.dateFirstDonation', header: 'Date First Donation' },
      { key: 'donationHistory.dateLastDonation', header: 'Date Last Donation' },
      { key: 'donationHistory.amountLastDonation', header: "Amount Last Donation"  },
      { key: 'donationHistory.typeLastDonation', header: "Type Last Donation"  },
      { key: 'donationHistory.minDonationOTG', header: 'Min OTG' },
      { key: 'donationHistory.maxDonationOTG', header: 'Max OTG' },
      { key: 'donationHistory.meanDonationOTG', header: 'Mean OTG' }
    ]
  }
};

export default fields;
