import React, {useState, Fragment} from 'react';
import {Modal} from "react-bootstrap";
import Refund from "./Refund";
import CancelSubscription from "./CancelSubscription";
import ReactTable from 'react-table';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import numeral from 'numeral';
import moment from "moment";
import ExportButton from "./ExportButton";
import { Link } from "react-router-dom";
import EditCheque from "./EditCheque";
import {
  Card,
  Col,
  Row,
  CardBody,
  CardHeader,
  Button
} from "shards-react";


export const GET_ACTIONS = gql`
  query getActions($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getActions(selector: $selector, limit: $limit, skip: $skip, sort: $sort) {
      count
      actions {
        _id
        date
        supporterId
        prospectId
        note
        comment
        service
        campaign
        data
        type
        description
        method
        supporter {
          firstName
          lastName
        }
      }
    }
  }
`;
const actionType = {
  'create'   : "Subscription",
  'process'  : "One Time Gift",
  'register' : "One Time Gift",
  'cancel'   : "Subscription"
};



const ActionTable =({campaignId, campaignNickname}) => {


  //modal Edit Cheque
  const [showEditCheque, setShowEditCheque] = useState(false);

  const handleCloseEditCheque = () => setShowEditCheque(false);
  const handleShowEditCheque = () => setShowEditCheque(true);

  const [ loading, setLoading ] = useState(false);
  const [ pages, setPages ] = useState(0);
  const [query, setQuery] = useState({
    'campaign._id'  : campaignId,
    'method'        : {$in : ["create", "process", "register"]},
    // 'data.status'   : {$nin : ["fail"]},
    limit           : 20
  });

  //sets the columns to display for our actions table
const columns = [{
  Header: 'Donation',
  accessor: 'method',
  className: "text-center",
  Cell: row => {
    return !!actionType[row.original.method] && actionType[row.original.method] || row.original.method;
  }
}, {
  Header: 'Supporter',
  accessor: 'supporter',
  className: "text-center",
  Cell: row => {
    if (!!row.original.supporter.firstName && !!row.original.supporter.lastName) {
      return <Link to={`/supporter/${row.original.supporterId}`}>{`${row.original.supporter.firstName} ${row.original.supporter.lastName}`}</Link>;
    } else
    return row.original.supporterId
  }
}, {
  Header: 'Payment Method',
  className: "text-center",
  accessor: 'data.paymentMethod'
}, {
  Header: 'Date',
  className: "text-center",
  accessor: 'ridingName',
  Cell: row => {
    return moment(row.original.date).format("lll")
  }
}, {
  Header: 'amount',
  className: "text-center",
  accessor: 'data.amount',
  Cell: row => {
    //if there is no amount to show return null
    if(!row.original.data || !row.original.data.amount) {
      return null
      //if the charge has failed, return declined
    } else if (!!row.original.data && !!row.original.data.status && row.original.data.status == "fail") {
      return "declined"
    }
    return <span>{`$${numeral(row.original.data.amount/100 ).format("0,0.00")}`}</span>
  }

},{
  Header: 'Actions',
  accessor: "supporterId",
  sortable: false,
  Cell: row => {
    const method = row.original.method;
    //shows the refund button if it's a payment and it hasn't been refunded
    if (method === "process" && !row.original.data.refund && !!row.original.data.documentId && row.original.data.status === "success") {
      return  (<Refund
      chargeId = {row.original.data.documentId}
      amount = {row.original.data.amount}
      supporterId = {row.original.supporterId}
      campaign = {row.original.campaign}
    />);
    //shows the cancel button if it's a subscription and it hasn't been canceled
    } else if (method === "create" && !row.original.data.canceled) {
      return  (<CancelSubscription
      subscriptionId={row.original.data.documentId}
      supporterId = {row.original.supporterId}
      nickname={row.original.data.plan}
      bankAccount={row.original.data.type == 'bank_account'}
      campaign={row.original.campaign}
      campaignView={true}
    />);
    } else if (row.original.data.paymentMethod === "cheque" && row.original.data.status == "success") {
      // const formattedCheque = {
      //   id : row.original._id,
      //   amount : row.original.data.amount

      // }
      return(<Fragment>

          <Button
            onClick={()=> { handleShowEditCheque()} }>
            Edit
          </Button>
          <Modal
            size="md"
            // centered
            show={showEditCheque}
            onHide={handleCloseEditCheque}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit cheque</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <EditCheque
                cheque={row.original}
                supporterId={row.original.supporterId}
                amount={row.original.data.amount}
                status={row.original.data.status}
                handleClose={handleCloseEditCheque}
              />
            </Modal.Body>
          </Modal>
      </Fragment>)
    }
    return null;
  }
}
];

  //if a subscription or charge has been canceled or refunded , gray out the row
  const getTrProps = (state, rowInfo, instance) => {
    const canceled = !!rowInfo && !!rowInfo.original.data && !!rowInfo.original.data.canceled || null;
    const failed   = !!rowInfo && !!rowInfo.original.data && rowInfo.original.data.status === "failure";
    const refunded = !!rowInfo && !!rowInfo.original.data && !!rowInfo.original.data.refund || null;
    if (!!rowInfo) {
      return {
        style: {
          background : (!!canceled || !!refunded || !!failed) ? 'Gainsboro' : '',
          color      : (!!canceled || !!refunded || !!failed) ? 'grey' : ''
        }
      }
    }
    return {};
  }

  const opts = {
    variables: {
      selector : query,
      limit    : (20 > query.limit) ? parseInt(query.limit) : 20,
      skip     : 0,
      sort     : { date: -1 }
    },
    fetchPolicy : "network-only"
  };

  const { error, data, fetchMore } = useQuery(GET_ACTIONS, opts );
  if (error) {
    console.log({error});
    return `Error! ${error}`;
  }
  return (
    <Card>
      <CardHeader>
        <Row className="justify-content-md-center">
          <Col sm ="10">
            <h6 className="text-center">
              Campaign Subscriptions & Gifts

            </h6>
          </Col>
          <Col className="text-right">
              <ExportButton
                  queryReport = {{'campaign._id' : campaignId, collection : "actions" }}
                  collection={"transactions"}
                  columns = {[
                      {key: 'date', header: 'Date'},
                      {key: 'service', header: 'Service'},
                      {key: "data.paymentMethod", header : "Payment Method"},
                      {key : "method", header : "Method"},
                      {key :"data.status", header : "Status"},
                      {key :"cancelled", header : "Cancelled"},
                      {key : "refundDate", header : "Refund Date"},
                      {key : "data.isPAC", header : "Supporter Is PAC"},
                      {key : "campaign.nickname", header : "Campaign Name"},
                      {key : "firstName", header : "Supporter First Name"},
                      {key : "lastName", header : "Supporter Last Name"},
                      {key : "address1", header : "Supporter Address 1"},
                      {key : "address2", header : "Supporter Address 2"},
                      {key : "postalCode", header : "Supporter Postal Code"},
                      {key : "city", header : "Supporter City"},
                      {key : "province", header : "Supporter Province"},
                      {key : "email", header : "Supporter Email"},
                      {key : "contactPreferences.okContact", header : "OK to Contact"},
                      {key : "contactPreferences.okMail", header : "OK to Mail"},
                      {key : "contactPreferences.okPremiums", header : "OK for Premiums"},
                      {key : "PIN", header : "Supporter PIN"},
                      {key : "data.amount", header : "Amount"},
                      {key : "user.firstName", header : "User First Name"},
                      {key : "user.lastName", header : "User Last Name"},
                      {key : "data.opts.utm_campaign", header : "UTM_Campaign"},
                      {key : "data.opts.utm_source", header : "UTM_Source"},
                      {key : "data.opts.utm_content", header : "UTM_Content"},
                      {key: "data.opts.receive_gift_optin", header: "Receive_Gift"},
                      {key: "data.opts.socks-selection", header: "Socks Selection"},
                  ]}
                  name = {campaignNickname}

              />
          {/* </Col> */}
          {/* <Col sm="2"> */}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ReactTable
          data={!!data && !!data.getActions ? data.getActions.actions : []}
          columns={columns}
          loading={loading}
          defaultPageSize={20}
          getTrProps={getTrProps}
          pageIndex={0}
          showPageJump = {false}
          manualPagination={true}
          manual
          noDataText={`No data to display`}
          pages={ pages }
          onFetchData={(state, instance) =>  {
            //create sort object with default being createdDate desc
            let sort = { date: -1 }
            state.sorted.forEach( (sortField, i) => {
              //if we have anything here, overwrite default
              if ( i == 0 ) {
                sort = {};
              }
              sort[sortField.id] = !!sortField.desc && -1 || 1;
            });

            //tell the table we are loading

            setLoading(true);

            //get the data
            fetchMore({
              variables: {
                skip: state.pageSize * state.page,
                limit: state.pageSize,
                sort
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                //update the data
                if (!fetchMoreResult) {
                  setPages(Math.ceil(prev.getActions.count / state.pageSize));
                  return prev;
                }
                setPages(Math.ceil(fetchMoreResult.getActions.count / state.pageSize));
                const newObj = {
                  getActions: {
                    ...prev.getActions,
                    actions : fetchMoreResult.getActions.actions
                  }
                };
                //disable loading state
                setLoading(false);
                return Object.assign({}, prev, newObj);
              }
            });
          }}
        />
      </CardBody>
    </Card>
  );
};


export default ActionTable;
